<!-- src/components/Footer.vue -->
<template>
  <footer>
    <!-- 여기에 하단 내용을 작성합니다 -->
    <p>hr@banseog.co.kr / 02-2177-9801</p>
      <p>반석써치(주). 서울특별시 강남구 테헤란로64길 16-8.</p>
        <p>(대치동,금척빌딩 4층). 대표자 이동기. 사업자 등록번호 109-86-07022</p>

  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
/* Footer 스타일을 여기에 작성합니다 */
footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px;
}
</style>
