<template>
  <div id="app">
    <nav class="navbar">
      <div class="top-bar">
        <button class="hamburger" @click="toggleMobileMenu" v-if="isMobile">☰</button>
      </div>

      <!-- 모바일용 메뉴 컨테이너 -->
      <transition name="slide">
        <div class="mobile-menu" v-if="mobileMenuVisible">
          <ul class="mobile-nav-list">
            <img src="../assets/111.png" class="mobile-logo" alt="Company Logo"
                 @click="goHome"
                 :class="{ 'active-logo': isLogoActive }"
                 @mousedown="isLogoActive = true"
                 @mouseup="isLogoActive = false"
                 @mouseleave="isLogoActive = false" />
            <!-- 모바일 메뉴 항목들을 반복 -->
            <li class="mobile-nav-item" v-for="menu in menus" :key="menu.text">
              {{ menu.text }}
              <!-- 모바일 하위 메뉴 -->
              <ul class="mobile-submenu">
                <li v-for="item in menu.items" :key="item.text">
                  <router-link :to="item.path">{{ item.text }}</router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </transition>

      <!-- 데스크탑용 메뉴 -->
      <ul class="nav-list" v-if="!isMobile" @mouseenter="submenuVisible = true" @mouseleave="submenuVisible = false">
        <img src="../assets/111.png" class="menu-logo" alt="Company Logo"
             @click="goHome"
             :class="{ 'active-logo': isLogoActive }"
             @mousedown="isLogoActive = true"
             @mouseup="isLogoActive = false"
             @mouseleave="isLogoActive = false" />
        <li class="nav-item" v-for="menu in menus" :key="menu.text">
          {{ menu.text }}
          <!-- 하위 메뉴를 v-show로 조건부 렌더링 -->
          <ul class="submenu" v-show="submenuVisible">
            <!-- 하위 메뉴 항목을 반복 -->
            <li v-for="item in menu.items" :key="item.text">
              <router-link :to="item.path">{{ item.text }}</router-link>
            </li>
          </ul>
        </li>
      </ul>
      <div class="gtranslate_wrapper"></div>
    </nav>
  </div>
</template>


<script>
export default {
  data() {
    return {
      menus: [
        {
          text: '반석써치(주) 소개',
          items: [
            { text: '반석써치 서비스강점', path: '/service-strength' },
            { text: '컨설턴트 소개', path: '/consultant-intro' },
            { text: '컨설턴트채용', path: '/consultant-hiring' },
            { text: '회사조직도', path: '/company-jojik' },
            { text: '문의/찾아오시는 길', path: '/company-load' },
            { text: '회사소개/CEO인사말', path: '/company-introduce' }
          ]
        },
        {
          text: '채용정보',
          items: [
            { text: '채용정보 전체검색', path: '/job-search' },
            { text: '회원가입 및 이력서 등록', path: '/register' },
            { text: '회원정보 수정', path: '/client-fix' },
            { text: '채용 프로세스', path: '/hire-process' },
            { text: '아이디/비밀번호 찾기', path: '/id-password-search' }
          ]
        },

        {
          text: '헤드헌팅의뢰',
          items: [
            { text: '헤드헌팅 프로세스', path: '/headhunting-process' },
            { text: '헤드헌팅 의뢰', path: '/headhunting-ask' },
            { text: '평판조회 프로세스', path: '/goal-procees' },
            { text: '평판조회 의뢰', path: '/goal-ask' },
          ]
        },

        {
          text: 'HR컨설팅',
          items: [
            { text: '개인 경력관리 컨설팅', path: '/carrer-consultant' },
            { text: '기업 HR 컨설팅', path: '/company-consultant' },
            { text: '컨설팅 사례', path: '/consultant-instance' }
          ]
        },

        {
          text: 'HR 자료실',
          items: [
            { text: '공지사항', path: '/notice' },
            { text: '산업계동향 & 정보', path: '/indusrty-info' },
            { text: '근로기준법 판례 및 사례', path: '/work-law' }
          ]
        },

        {
          text: '문의사항',
          items: [
            { text: '자주 묻는 질문', path: '/usual-ask' },
            { text: '고객 상담실', path: '/client-talk' }
          ]
        }
      ],
      submenuVisible: false, // 모든 메뉴 항목에 대한 하위 메뉴 표시 상태를 관리합니다.
      isMobile: false, // 현재 모바일 화면인지 여부
      mobileMenuVisible: false, // 모바일 메뉴가 표시되는지 여부
            isLogoActive: false // 로고가 클릭된 상태인지 여부
    };
  },

  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize(); // 컴포넌트가 생성될 때 화면 크기를 확인합니다.
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    toggleMobileMenu() {
      this.mobileMenuVisible = !this.mobileMenuVisible;
    },
    handleResize() {
      this.isMobile = window.innerWidth < 1000; // 1000px 미만이면 모바일 화면으로 간주합니다.
    },
    goHome() {
      // 현재 경로와 이동하려는 경로가 동일한지 확인
      if (this.$route.path !== '/') {
        this.$router.push({ name: 'home' });
      }
    }
  },

  mounted() {
    // gtranslate 설정
    window.gtranslateSettings = {
      default_language: "ko",
      native_language_names: true,
      languages: ["ko", "en", "zh-CN", "ja", "de", "fr"],
      wrapper_selector: ".gtranslate_wrapper",
      switcher_horizontal_position: "right",
      switcher_vertical_position: "top",
      float_switcher_open_direction: "bottom",
      alt_flags: {
        en: "usa"
      }
    };

    // 스크립트 동적 추가
    const script = document.createElement('script');
    script.src = 'https://cdn.gtranslate.net/widgets/latest/float.js';
    script.defer = true;
    document.head.appendChild(script);
  }
};
</script>

<style>
.navbar {
  background: #f8f8f8;
  width: 100%;
  padding-top: 20px;
  padding-left: 0;
  margin-left: 0;
  border-bottom: 2px solid black;
  position: relative;
  z-index: 10;
}

.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}

.nav-item {
  position: relative;
  padding: 0.5rem 1rem;
  border-radius: 0;
}

.nav-item li {
  padding: 0.5rem 1rem;
  display: block;
}

.submenu {
  display: none;
  flex-direction: column;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  min-width: 100%;
  z-index: 20;
}

.submenu li {
  padding: 0.5rem 1rem;
  display: block;
}

.nav-item:hover .submenu {
  display: flex;
}

/* 모바일 화면에서 햄버거 메뉴 버튼 스타일 */
.mobile-menu {
  display: none;
  /* 추가 스타일 */
}

.hamburger {
  display: none;
  /* 추가 스타일 */
}

.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.slide-enter-active, .slide-leave-active {
  transition: transform 0.3s ease-out;
}

.slide-enter, .slide-leave-to {
  transform: translateX(-100%);
}

.mobile-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 75%;
  background-color: white;
  box-shadow: 4px 0 5px rgba(0,0,0,0.2);
  transition: transform 0.3s ease-out;
  z-index: 999;
}

.mobile-logo {
  display: none;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.menu-logo {
  display: block;
  cursor: pointer; /* 마우스 커서를 포인터로 변경 */
  transition: opacity 0.3s ease; /* 클릭 시 애니메이션 효과 */
}

.menu-logo.active-logo,
.mobile-logo.active-logo {
  opacity: 0.6;
}

/* 미디어 쿼리 */
@media (max-width: 1000px) {
  .mobile-menu {
    overflow-y: auto;
    display: block;
  }

  .nav-list {
    display: none;
  }

  .hamburger {
    display: block;
    font-size: 30px;
  }

  .top-bar {
    display: flex;
    justify-content: space-between;
  }

  .mobile-logo {
    display: block;
    width: auto;
    height: auto;
  }

  .menu-logo {
    display: none;
  }

  .mobile-nav-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
