import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/client-talk',
    name: 'ClientTalk',
    component :  () => import('../components/ClientTalk')

  },
  {
    path: '/register',
    name: 'Signup',
    component :  () => import('../components/Signup')

  }
     ,
  {
    path: '/signup2',
    name: 'Signup2',
    component :  () => import('../components/Signup2')

  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
