<template>
  <div id="app">
   <Navbar />
     <Chatbot/>
    <router-view/>
  <Footer/>

  </div>
</template>







<script>
import Chatbot from './views/Chatbot.vue';
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';


export default {
  components: {
    Navbar,
    Chatbot,
    Footer
  },

}




</script>

<style>


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}
img {
  max-width: 100%;
  height: auto;
}
nav {
  padding: 20px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
