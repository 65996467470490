 <template>
  <div class="home">
  <img :src="currentImage" alt="슬라이드 이미지" class="home-image">


    <div class="message-and-buttons">
    <HelloWorld msg="반석써치에 오신걸 환영합니다."/>
    <div class="welcome-message">

    </div>
       <div class="buttons">
    <button class="custom-btn btn-15">[인재구인(기업)]</button>
    <button class="custom-btn btn-15">[지원자(개인)]</button>
    <button class="custom-btn btn-15">[헤드헌터 지원]</button>
      </div>
     </div>
    </div>

</template>

<script>
// @ is an alias to /src
 import HelloWorld from '@/components/HelloWorld.vue'

 export default {
   name: 'HomeView',
   components: {
     HelloWorld
   },
   data() {
     return {
     images: [
             require('@/assets/a11.png'),
             require('@/assets/a12.png'),
             require('@/assets/a13.png')
           ],
       currentIndex: 0
     };
   },
   computed: {
     currentImage() {
       return this.images[this.currentIndex];
     }
   },
   mounted() {
     this.startSlideshow();
   },
   beforeDestroy() {
     clearInterval(this.interval);
   },
   methods: {
     startSlideshow() {
       this.interval = setInterval(() => {
         this.currentIndex = (this.currentIndex + 1) % this.images.length;
       }, 5000); // 5초마다 변경
     }
   }
 };
</script>

<style scoped>
.home-image {
  max-width: 55%;
  height: auto;
  margin: 0 auto; /* 중앙 정렬을 위해 자동 마진을 설정합니다 */
   display: block; /* 블록 레벨 요소로 설정합니다 */

   border: 4px solid black; /* 굵은 검은색 테두리 */
 box-shadow: 8px 8px 0px rgba(0,0,0,0.7); /* 강한 그림자 효과 */
 filter: contrast(120%); /* 대비를 높여 색상을 강렬하게 */

}

.home-image:hover {
  filter: brightness(110%) contrast(150%); /* 마우스 오버시 밝기와 대비를 증가 */
  transform: scale(1.05); /* 이미지를 살짝 확대 */
  transition: all 0.3s ease; /* 부드러운 전환 효과 */
}


.home {
  display: flex;
  display: flex;
   flex-direction: column; /* 세로 방향으로 요소들을 정렬합니다 */
   justify-content: center; /* 요소들을 중앙 정렬합니다 */
   flex-wrap: wrap;
}

button {
  margin: 20px;
  outline: none;
}

.message-and-buttons {
  text-align: center; /* 메시지와 버튼을 가운데 정렬합니다 */
}

.buttons {
display: flex;
flex-direction: row; /* 버튼들을 가로로 배치합니다 */
justify-content: center; /* 버튼들을 수평 가운데 정렬합니다 */
margin-top: 20px; /* 메시지와 버튼 사이에 여백 추가 */
}

.custom-btn {
width: 33%; /* 화면 너비의 3분의 1로 설정합니다 */

  height: 200px;
  padding: 10px 25px;
  border: 2px solid #000;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  font-size: 40px;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;

}

/* 15 */
.btn-15 {
   background: #000;
  color: #fff;
  z-index: 1;
}
.btn-15:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
   background: #e0e5ec;
  transition: all 0.3s ease;
}
.btn-15:hover {
  color: #000;
}
.btn-15:hover:after {
  left: 0;
  width: 100%;
}
.btn-15:active {
  top: 2px;
}

@media (max-width: 768px) {
.buttons {
   flex-direction: column; /* 모바일 화면에서 버튼들을 세로로 배치합니다 */
   align-items: center; /* 버튼들을 수직 가운데 정렬합니다 */
 }
 .custom-btn {
   width: 100%; /* 모바일 화면에서 버튼 너비를 100%로 설정하여 한 줄에 하나씩 배치합니다 */
   margin: 10px 0; /* 버튼 사이에 여백을 추가합니다 */
 }
}
</style>
