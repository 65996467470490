<template>
  <div class="chatbot" :class="{ 'open': showChat }">
    <div v-if="showChat" class="chat-container">
      <h2>고객 상담 챗봇</h2>
      <div class="messages">
        <p v-for="message in messages" :key="message.id">{{ message.text }}</p>
      </div>
      <div v-if="currentQuestion">
        <p v-if="!currentQuestion.isHtml">{{ currentQuestion.text }}</p>
         <p v-else v-html="currentQuestion.text"></p>
        <button v-for="option in currentQuestion.options" :key="option.id" @click="handleAnswer(option)">
          {{ option.text }}
        </button>
      </div>
    </div>
    <button @click="toggleChat" :class="['custom-btn', chatButtonClass]">고객 상담</button>
  </div>
</template>


<script>
export default {
  data() {
    return {
      showChat: false,
      messages: [],
      questions: {
          start: {
            text: "어떤 정보가 필요하신가요?",
            options: [
              { id: "1", text: "고객사로 방문했습니다" },
              { id: "2", text: "지원자로 방문했습니다" },
              { id: "3", text: "헤드헌터를 지원하고 싶어요" },
            ],
          },
          "1": {
            text: "고객사 관련 질문입니다.",
            options: [
              { id: "1-1", text: "수수료는 얼마인가요?" },
              { id: "1-2", text: "회사에 원하는 사람을 뽑으려면 어떻게 해야 하나요?" },
            ],
          },
          "1-1": {
          text: "수수료 관련 정보: 수수료는 400만원입니다.",
       options: [] // 이 질문에 대한 후속 옵션이 없으면 빈 배열을 사용
          },
          "1-2": {
            text: "원하는 사람을 뽑으려면 먼저 메일로 연락을주세요. .",
            options: [],
          },

          "2": {
     text: "지원자 관련 질문입니다.",
     options: [
       { id: "2-1", text: "이력서를 어떻게 제출하나요?" },
       { id: "2-2", text: "헤드헌터와 연락 후 뽑히는 과정이 궁금해요" },
     ],
   },
   "2-1": {
   text: "이력서 제출 방법: 자세한 정보는 <a href='javascript:void(0)' onclick='goToRegister()'>여기</a>를 클릭하세요.",
         options: [],
         isHtml: true,
       },
   "2-2": {
     text: "헤드헌터와 연락 후 과정: [헤드헌터와 연락 후 과정에 대한 설명]",
     options: [],
   },
   "3": {
     text: "헤드헌터 지원 관련 질문입니다.",
     options: [
       { id: "3-1", text: "헤드헌터는 처음이에요" },
       { id: "3-2", text: "헤드헌터로 연락하려면 어디에 해야 하나요?" },
     ],
   },
   "3-1": {
     text: "헤드헌터 초보자 가이드: [헤드헌터 초보자에 대한 설명]",
     options: [],
   },
   "3-2": {
     text: "헤드헌터 연락처: [헤드헌터 연락처 정보]",
     options: [],
   },

          // 이하 같은 방식으로 "2", "2-1", "2-2", "3", "3-1", "3-2"에 대한 데이터 구조를 정의
        },
        currentQuestion: null,
      chatButtonClass: 'btn-14', // 초기 클래스 설정

    };
  },
  methods: {
    toggleChat() {
      this.showChat = !this.showChat;
      this.chatButtonClass = this.showChat ? 'btn-13' : 'btn-14'; // 창 상태에 따라 클래스 설정
         if (this.showChat) {
           this.currentQuestion = this.questions.start;
         }
    },
    handleAnswer(option) {
    if (this.questions[option.id]) {
      this.currentQuestion = this.questions[option.id];
    } else {
      this.messages.push({ id: this.messages.length, text: "선택하신 질문에 대한 답변: " + option.text });
      // 선택한 옵션에 따른 추가적인 행동 (예: 폼 표시, 외부 링크 열기 등)을 여기에 구현할 수 있습니다.
    }
  },
  goToRegister() {
window.location.href = '/register';
},

  },
  created() {
   // 컴포넌트가 생성될 때 초기 클래스를 설정합니다.
   this.chatButtonClass = 'btn-14';

  window.goToRegister = this.goToRegister;

 },
 beforeDestroy() {
  // 전역 함수 정리
  delete window.goToRegister;
}

};
</script>

<style>
.chatbot {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border: 1px solid #ccc;
  background-color: white;
}

.messages {
  height: 200px;
  overflow-y: auto;
}

.messages p {
  margin: 5px;
}

button {
  margin: 0px;
  outline: none;
}


.custom-btn {
  width: 130px;
  height: 40px;
  padding: 10px 25px;
  border: 2px solid #000;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;

  display: inline-block;
}

.btn-14 {
   background: #000;
  color: #fff;
  z-index: 1;
}
.btn-14:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  background: #e0e5ec;
  transition: all 0.3s ease;
}
.btn-14:hover {
  color: #000;
}
.btn-14:hover:after {
  top: auto;
  bottom: 0;
  height: 100%;
}
.btn-14:active {
  top: 2px;
}

.btn-13 {
   background: #000;
  color: #fff;
  z-index: 1;
}
.btn-13:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
   background: #e0e5ec;
  transition: all 0.3s ease;
}
.btn-13:hover {
  color: #000;
}
.btn-13:hover:after {
  top: 0;
  height: 100%;
}
.btn-13:active {
  top: 2px;
}
@media (max-width: 1000px) {
.chatbot {
display:none;
}



}


</style>
